<template lang="pug">
.flex.flex-col.w-full(style='height: 75vh')
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.geozones.control") }}
  p.text-annotationColor.text-base.font-SourceSansPro.font-semibold.mt-3 {{ $t("notifications.geozones.verificationType") }}
  .relative.mt-3.mb-8
    el-select.w-64(
      :disabled='isAllowedCreate && role !== "EDITOR"',
      v-model='notification.in_geozone',
      :class='[{ "input-error": validate }, "w-1/2"]'
    )
      el-option(
        v-for='item in options',
        :key='item.value',
        :label='item.label',
        :value='item.value'
      )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='validate') {{ $t("notifications.geozones.chooseVerficationType") }}
  .overflow-y-auto.mt-2.border-t.border-dividerColor.relative(
    @scroll.passive='handleScroll'
  )
    resize-observer(@notify='handleResize')
    .table.w-full
      .table-header-group.text-left
        .table-row
          .table-cell.bg-white.top-0.sticky
            span.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.geozones.geozones") }}
          .table-cell.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox(
              v-model='isAllChecked',
              :indeterminate='isIndeterminate',
              @change='checkAllUnits($event)',
              :disabled='!filterData.length',
              :error='isError'
            )
        .table-row.border-b.border-reallylightblue
          .table-cell.sticky.bg-white.z-10(style='top: 32px', colspan='2')
            skif-search.w-64.my-2(
              v-model='filterString'
              :placeholder='$t("search")'
              class='w-1/2'
              @searching="searching"
            )
      .table-row-group
        .table-row(:style='{ height: this.topPaddingHeight + "px" }')
        .table-row.border-b.border-reallylightblue(
          v-for='geozone in visibleItems',
          style='height: 43px'
        )
          .table-cell
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='geozone.isInArray ? "text-darkblue" : "text-annotationColor"'
            ) {{ geozone.name }}
          .table-cell.w-10
            skif-checkbox(
              v-model='geozone.isInArray',
              @change='changeChecked(geozone, $event)',
              :error='isError'
            )
        .table-row(:style='{ height: this.bottomPaddingHeight + "px" }')
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification', 'isInGeozone', 'admin'],
  data() {
    return {
      isAllChecked: false,
      isIndeterminate: false,
      filterString: '',
      isError: false,
      options: [
        { value: true, label: this.$t('geozones_lang.geozones_enter') },
        { value: false, label: this.$t('geozones_lang.geozones_exit') }
      ],
      cellHeight: 43,
      viewportHeight: 0,
      totalHeight: 0,
      topPaddingHeight: 0,
      bottomPaddingHeight: 0,
      bufferHeight: 0,
      toleranceHeight: 43 * 5,
      tolerance: 5,
      bufferedItems: 0,
      startIndex: 0
    }
  },
  computed: {
    visibleItems() {
      return this.filterData.slice(
        this.startIndex,
        this.startIndex + this.bufferedItems
      )
    },

    filterData() {
      if (this.admin) {
        return this.notification.geozones.reduce((result, geozone) => {
          const isInArray = this.notification.geozones
            .map((k) => k.id)
            .includes(geozone.id)
          return JSON.stringify(geozone)
            .toLowerCase()
            .includes(this.filterString.toLowerCase())
            ? [...result, { ...geozone, isInArray }]
            : result
        }, [])
      }
      return this.geozones.reduce((result, geozone) => {
        const isInArray = this.notification.geozones
          .map((k) => k.id)
          .includes(geozone.id)
        return JSON.stringify(geozone)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
          ? [...result, { ...geozone, isInArray }]
          : result
      }, [])
    },
    geozonesIds() {
      return this.filterData.map((k) => k.id)
    },
    ...mapGetters('geozones', {
      geozones: 'geozonesIdNameImei'
    }),
    ...mapGetters('login', ['role', 'disabledRole']),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    },
    validate: {
      get() {
        return this.isInGeozone
      },
      set(newValue) {
        this.$emit('updateInGeozone', newValue)
      }
    }
  },
  watch: {
    'filterData.length': function (val) {
      if (val < this.startIndex) {
        this.startIndex = 0
      }
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.totalHeight = val * this.cellHeight
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },

    'notification.in_geozone': function (val) {
      this.validate = val === ''
    },
    // 'notification.geozones': function (val) {
    //   const valIds = val.map((k) => k.id)
    //   this.isAllChecked = this.geozonesIds.every((geozonesId) =>
    //     valIds.includes(geozonesId)
    //   )
    //   this.isIndeterminate =
    //     !this.isAllChecked &&
    //     this.geozonesIds.some((geozonesId) => valIds.includes(geozonesId))
    // },
    filterData() {
      const valIds = this.notification.geozones.map((k) => k.id)
      this.isAllChecked =
        !!this.geozonesIds.length &&
        this.geozonesIds.every((geozonesId) => valIds.includes(geozonesId))
      this.isIndeterminate =
        !this.isAllChecked &&
        this.geozonesIds.some((geozonesId) => valIds.includes(geozonesId))
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    handleScroll(event) {
      this.startIndex = Math.round(event.target.scrollTop / this.cellHeight)
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },

    handleResize({ height }) {
      this.viewportHeight = height
      this.totalHeight = this.filterData.length * this.cellHeight
      this.bufferHeight = this.viewportHeight + 2 * this.toleranceHeight
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.bufferHeight,
        0
      )
      this.bufferedItems = Math.round(
        this.viewportHeight / this.cellHeight + 2 * this.tolerance
      )
    },

    tableError() {
      this.isError = true
    },
    checkAllUnits($event) {
      if ($event.target.checked) {
        // eslint-disable-next-line vue/no-mutating-props
        this.notification.geozones = this.filterData
        this.isError = false
        this.isAllChecked = true
        this.isIndeterminate = false
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.notification.geozones = []
        this.isError = true
        this.isAllChecked = false
        this.isIndeterminate = false
      }
    },
    changeChecked(geozone, $event) {
      // eslint-disable-next-line vue/no-mutating-props
      this.notification.geozones = $event.target.checked
        ? [...this.notification.geozones, geozone]
        : this.notification.geozones.filter(
            (notifyGeo) => notifyGeo.id !== geozone.id
          )
      const selectedLength = this.notification.geozones.length
      if (selectedLength === this.filterData.length) {
        this.isError = false
        this.isAllChecked = true
        this.isIndeterminate = false
      } else if (!selectedLength) {
        this.isError = true
        this.isAllChecked = false
        this.isIndeterminate = false
      } else {
        this.isError = false
        this.isAllChecked = false
        this.isIndeterminate = true
      }
    }
  },
  created() {
    const valIds = this.notification.geozones.map((k) => k.id)
    this.geozones.sort((a, b) => (valIds.indexOf(b.id) !== -1 ? 1 : -1))
    this.isAllChecked =
      !!this.geozonesIds.length &&
      this.geozonesIds.every((unitId) => valIds.includes(unitId))
    this.isIndeterminate =
      !this.isAllChecked &&
      this.geozonesIds.some((unitId) => valIds.includes(unitId))
  }
}
</script>
